import React, { useContext, useState } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import ErrorsList from "../ErrorsList";
import EducationRow from "./EducationRow";
import EducationSummaryRow from "./EducationSummaryRow";
import EducationTemp from "./EducationTemp";

const EducationUpdate = ({ onSubmit }) => {
  const { education, isLoading } = useContext(MemberDetails);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const memberEducationTemplate = {
    MemberId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    DegreeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    PassingYear: 0,
    StartDate: "2024-11-21T19:09:38.305Z",
    EndDate: "2024-11-21T19:09:38.305Z",
    InstitutionName: "string",
    FieldOfStudy: "string",
    DegreeGradeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    DegreePercentage: 0,
    DegreeDescription: "string",
    CreatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  };
  const [educationDetails, setEducationDetails] = useState(
    education.length ? education : [memberEducationTemplate]
  );

  const addEducation = (e) => {
    e.preventDefault();
    const newEducationDetails = [...educationDetails, memberEducationTemplate];
    const newSelectedRow = newEducationDetails.length - 1;
    setSelectedRow(newSelectedRow);
    setEducationDetails(newEducationDetails);
  };
  console.log(educationDetails);

  return (
    <form onSubmit={onSubmit}>
      <div className="tab-pane">
        <h4 className="sub-title mb-4">Education Detail</h4>
        <div className="" id="add-education-detail">
          {educationDetails.map((item, index) => (
            <EducationRow
              key={index}
              values={item}
              isSubmitting={isSubmitting}
            />
          ))}
        </div>
        {/* <div className="" id="add-education-detail">
          <div className="accordion">
            {educationDetails.map((item, index) => (
              <EducationTemp />
            ))}
          </div>
        </div> */}

        <div>
          <button
            className="btn green-btn mt-4"
            id="add-education-btn"
            onClick={addEducation}
          >
            <i className="fas fa-plus"></i> Add Row
          </button>
        </div>
        <ul class="w-full list-inline text-right d-flex justify-content-end">
          <li>
            {isSubmitting ? (
              <button className="btn green-btn px-5 cursor-na" disabled>
                <Spinner size="sm" />
              </button>
            ) : (
              <input type="submit" className="btn green-btn" value="Save" />
            )}
          </li>
        </ul>
      </div>
    </form>
  );
};

export default EducationUpdate;
