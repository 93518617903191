import React from "react";
import { useForm } from "react-hook-form";
import ErrorsList from "../ErrorsList";

const EducationRow = ({ isSubmitting, values }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, reset, isDirty },
    setValue,
  } = useForm();
  return (
    <>
      <div className="row add-education-row mt-4 p-3 pb-0">
        {/* <h5 className="col-md-12 p-2">Enter Education details</h5> */}
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Highest Degree"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.StartDate && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.StartDate && isDirty
                  ? errors.StartDate.message
                  : "Start Date"
              }
              {...register("StartDate", {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid Start Date",
                },
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.EndDate && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.EndDate && isDirty ? errors.EndDate.message : "End Date"
              }
              {...register("EndDate", {
                max: {
                  value: new Date().toLocaleDateString(),
                  message: "Please select a valid End Date",
                },
              })}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.InstitutionName && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.InstitutionName && isDirty
                  ? errors.InstitutionName.message
                  : "Institution"
              }
              {...register("InstitutionName", {
                required: "Please enter your institution name",
              })}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.DegreeDescription && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.DegreeDescription && isDirty
                  ? errors.DegreeDescription.message
                  : "Degree description"
              }
              {...register("DegreeDescription", {
                required: "Please enter",
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.FieldOfStudy && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.FieldOfStudy && isDirty
                  ? errors.FieldOfStudy.message
                  : "Field of Study"
              }
              {...register("FieldOfStudy", {
                required: "Please enter your field of study",
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.DegreePercentage && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.DegreePercentage && isDirty
                  ? errors.DegreePercentage.message
                  : "Percentage"
              }
              {...register("DegreePercentage", {
                required: "Please enter your percentage",
              })}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.PassingYear && isDirty
                  ? "red-placeholder border border-danger"
                  : ""
              }`}
              placeholder={
                errors.PassingYear && isDirty
                  ? errors.PassingYear.message
                  : "Passing Year"
              }
              {...register("PassingYear", {
                required: "Please enter your passing year",
              })}
            />
          </div>
        </div>
      </div>
      <ErrorsList isDirty={isDirty} errors={errors} />
    </>
  );
};

export default EducationRow;
