import React, { useContext } from "react";
import { MemberDetails } from "../../Contexts/MemberDetailContext";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { URL_PATH } from "../../Utils/Constants/constants";

const MembershipProfile = () => {
  const { isLoading, memberDetails, education, workExperience, error } =
    useContext(MemberDetails);

  if (error) {
    return (
      <div
        classNameName="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <h1>{error}</h1>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        classNameName="d-flex justify-content-center align-items-center"
        style={{ minHeight: "500px" }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      <div className="view-member-profile">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="row">
              <div className="col-md-3">
                <div className="profile-img">
                  <img src={memberDetails?.DisplayURL} alt="" />
                </div>
              </div>
              <div className="col-md-9">
                <div className="profile-detail">
                  <div className="mb-4">
                    <h2
                      className="sub-title-2"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {`${memberDetails.FirstName} ${memberDetails.LastName}` ??
                        ""}
                    </h2>
                    <h3 className="sub-title-3">
                      {memberDetails?.CurrentDesignation ?? ""}
                    </h3>
                  </div>
                  <div>
                    <Link
                      to={URL_PATH.memberprofileedit}
                      className="btn green-btn"
                    >
                      Edit Profile
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tab-1"
                  >
                    Personal Info
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tab-2">
                    Education Detail
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tab-3">
                    Work Exp Details
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane container active" id="tab-1">
                  <div className="view-member-profile-detail">
                    <ul className="view-profile-list">
                      <li>
                        <strong>Full Name: </strong>{" "}
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {`${memberDetails.FirstName} ${memberDetails.LastName}` ??
                            "NA"}
                        </span>
                      </li>
                      <li>
                        <strong>Date of Birth: </strong>{" "}
                        <span>
                          {new Date(memberDetails?.DOB).toLocaleDateString(
                            "en-US"
                          ) ?? "NA"}
                        </span>
                      </li>
                      <li>
                        <strong>Gender: </strong>{" "}
                        <span>{memberDetails?.Gender ?? "NA"} </span>
                      </li>
                      <li>
                        <strong>Address: </strong>{" "}
                        <span> {memberDetails?.AddressLine1 ?? "NA"}</span>
                      </li>
                      <li>
                        <strong>Phone Number: </strong>{" "}
                        <span>{memberDetails?.PhoneNumber ?? "NA"}</span>
                      </li>
                      <li>
                        <strong>Email Address: </strong>{" "}
                        <span>{memberDetails?.Email ?? "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-pane container fade" id="tab-2">
                  <div className="view-member-profile-detail">
                    {education.length ? (
                      <ul className="view-profile-list">
                        <li>
                          <strong>Degree: </strong>{" "}
                          <span>{education?.DegreeName ?? ""}</span>
                        </li>
                        <li>
                          <strong>University/College Name: </strong>{" "}
                          <span>{education?.InstitutionName ?? ""}</span>
                        </li>
                        <li>
                          <strong>Field of Study: </strong>{" "}
                          <span>{education?.FieldOfStudy ?? ""}</span>
                        </li>
                        <li>
                          <strong>Graduation Year: </strong>{" "}
                          <span>{education?.PassingYear ?? ""}</span>
                        </li>
                        {/* <li>
                        <strong>Additional Certifications: </strong>{" "}
                        <span>
                          {" "}
                          Certified ScrumMaster (CSM), AWS Certified Solutions
                          Architect
                        </span>
                      </li> */}
                      </ul>
                    ) : (
                      <>
                        <p>Education details not available</p>
                        <Link to={URL_PATH.memberprofileedit}>
                          Edit Profile
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="tab-pane container fade" id="tab-3">
                  <div className="view-member-profile-detail">
                    {workExperience.length ? (
                      <ul className="view-profile-list">
                        <li>
                          <strong>Job Title: </strong>{" "}
                          <span>{workExperience?.JobTitle ?? ""}</span>
                        </li>
                        <li>
                          <strong>Company Name: </strong>{" "}
                          <span>{workExperience?.CompanyName ?? ""}</span>
                        </li>
                        <li>
                          <strong>Start Date: </strong>{" "}
                          <span>{workExperience?.StartDate ?? ""}</span>
                        </li>
                        <li>
                          <strong>End Date: </strong>{" "}
                          <span> {workExperience?.EndDate ?? ""}</span>
                        </li>
                        <li>
                          <strong>Job Location: </strong>{" "}
                          <span> {workExperience?.JobLocation ?? ""}</span>
                        </li>
                        {/* <li>
                        <strong>Responsibilities: </strong>
                        <ul>
                          <li>
                            Developing and maintaining scalable web applications
                          </li>
                          <li>
                            Collaborating with cross-functional teams to define
                            project requirements
                          </li>
                          <li>
                            Writing clean, efficient, and maintainable code
                          </li>
                          <li>
                            Participating in code reviews and providing
                            constructive feedback
                          </li>
                          <li>Debugging and troubleshooting software issues</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Skills Acquired: </strong>
                        <ul>
                          <li>
                            Proficiency in programming languages such as Python,
                            JavaScript, and Go
                          </li>
                          <li>
                            Experience with cloud services like Google Cloud
                            Platform (GCP)
                          </li>
                          <li>Strong problem-solving and analytical skills</li>
                          <li>Knowledge of Agile methodologies</li>
                          <li>
                            Effective communication and teamwork abilities
                          </li>
                        </ul>
                      </li> */}
                      </ul>
                    ) : (
                      <>
                        <p>Work experience not available</p>
                        <Link to={URL_PATH.memberprofileedit}>
                          Edit Profile
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipProfile;
